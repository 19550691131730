//defaults context
const tagDefaults = [{
    url: `gr`,
    mkt: `greece`,
    lng: `el`,
    sct: `general`,
},
{
    url: `com`,
    mkt: `ww`,
    lng: `en`,
    sct: `general`,
}]

const def = 0

//*****Takes the Url and Tags from the node and returns a synthetic URL accorting to tags and default context settings */

const UrlForm = (node) => {
    //console.log(post.node)
    const url = node.url
    //console.log(node)
    //remove first part
    const regex = /http(s?):\/*[0-9.a-zA-Z]*\//
    const cleanUrl = url.replace(regex, ``)
    //console.log(cleanUrl)

    const postTags = node.tags
    const tagMap = new Map()
    let catList = ``
    const typeSep = RegExp(`@`)
    const hash = /#/
    postTags.map((tag) => {
        //regex split for category
        if (typeSep.test(tag.name) && tag.visibility === `internal`) {
            const tagWcat = tag.name.split(typeSep)
            tagWcat[0] = tagWcat[0].replace(hash, ``)
            // if category not exists create new
            tagMap.set(tagWcat[0], tagWcat[1])
            //for now only one value per tag type keeps the last on
            //     console.log(tagTable)
        } else if (tag.visibility === `public`) {
            catList = catList + tag.slug + `/`
        }
    })
    tagMap.set(`cat`, catList)
    //console.log(tagMap)

    let mkt = tagMap.has(`mkt`) && tagMap.get(`mkt`) !== tagDefaults[def].mkt ? `${tagMap.get(`mkt`)}/` : ``
    let lng = tagMap.has(`lng`) && tagMap.get(`lng`) !== tagDefaults[def].lng ? `${tagMap.get(`lng`)}/` : ``
    let sct = tagMap.has(`sct`) && tagMap.get(`sct`) !== tagDefaults[def].sct ? `${tagMap.get(`sct`)}/` : ``
    let cat = catList
    //iterate for each value of set and output an array >for after

    // URL shortening for defaults

    //sample url structure including all tag categories
    let finurl = `/${mkt}${lng}${sct}${cat}${cleanUrl}`

    // array of different urls that belongs? Not for now
    //Replace old url with new

    return finurl
}

// ****Takes a URL with tags inside and removes the tags and default settings to simplify it
const UrlDeform = (url) => {
    //console.log(url)
    //initial filtering for removal http/s and domain
    const regexStart = /http(s?):\/*[0-9.a-zA-Z]*\//
    let cleanUrlStart = url.replace(regexStart, ``)
    const regexEnd = /\/$/
    let cleanUrlEnd = cleanUrlStart.replace(regexEnd, ``)
    const regStart = /^\//
    let cleanUrl = cleanUrlEnd.replace(regStart, ``)
    //console.log(cleanUrl)
    // splitting rest of url in tag table
    const seperator = RegExp(`/`)
    const tagSign = RegExp(`@`)
    const hash = /#/
    const tagTable = cleanUrl.split(seperator)
    //seperating values from tags and putting them in a map
    const tagMap = new Map()
    const categorySet = []
    tagTable.map((tag) => {
        const tagElem = tag.split(tagSign)
        //console.log(`tagelem 0 `,tagElem[0],`tagelem 1 `, tagElem[1])
        if (tagElem[1] === undefined) {
            // it will keep only the last part as a slug
            tagMap.set(`slugUrl`, tagElem[0])
        } else if (tagElem[0] === `#cat`){
            categorySet.push(tagElem[1])
        } else {
            tagElem[0] = tagElem[0].replace(hash, ``)
            tagMap.set(tagElem[0],tagElem[1])
        }
    })
    categorySet.size > 0 ? tagMap.set(`cat`, categorySet) : null
    //console.log(tagMap)
    // Να προσθέσω φίλτρο για slug url περίπτωση if και τοποθέτηση μέσα στο map
    // Να προσθέσω περίπτωση category όπου θα αποθηκεύει σε λίστα

    // recostructing final url shortened and with removed tag types
    let mkt = tagMap.has(`mkt`) && tagMap.get(`mkt`) !== tagDefaults[def].mkt ? `${tagMap.get(`mkt`)}/` : ``
    let lng = tagMap.has(`lng`) && tagMap.get(`lng`) !== tagDefaults[def].lng ? `${tagMap.get(`lng`)}/` : ``
    let sct = tagMap.has(`sct`) && tagMap.get(`sct`) !== tagDefaults[def].sct ? `${tagMap.get(`sct`)}/` : ``
    let cat = tagMap.has(`cat`) ? tagMap.get(`cat`).map(category => `${category}/`) : ``
    let slugUrl = tagMap.has(`slugUrl`) ? `${tagMap.get(`slugUrl`)}/` : ``
    //console.log(mkt, lng, sct, cat, slugUrl)
    // i have to fix categories
    let finurl
    if (tagMap.has(`pg`)) {
        finurl = `/${lng}${tagMap.get(`pg`)}`
    } else {
        finurl = `/${mkt}${lng}${sct}${cat}${slugUrl}`
    }

    // put final url to map with key furl
    tagMap.set(`furl`, finurl)
    //console.log(`**Tag map**`,tagMap)
    //a map with a simplified final url and tags for filtering
    return tagMap
}
//UrlDeform(url).get(`furl`)

const UrlNav = (node) => {
    //console.log(post.node)
    const url = node.url
    //console.log(node)
    //remove first part
    const regex = /http(s?):\/*[0-9.a-zA-Z]*\//
    let cleanUrl = url.replace(regex, ``)
    // console.log(cleanUrl)

    const postTags = node.tags
    const tagMap = new Map()
    let catList = ``
    const typeSep = RegExp(`@`)
    const hash = /#/
    postTags.map((tag) => {
        //regex split for category
        if (typeSep.test(tag.name) && tag.visibility === `internal`) {
            const tagWcat = tag.name.split(typeSep)
            tagWcat[0] = tagWcat[0].replace(hash, ``)
            // if category not exists create new
            tagMap.set(tagWcat[0], tagWcat[1])
            //for now only one value per tag type keeps the last on
        //     console.log(tagTable)
        } else if (tag.visibility === `public`) {
            catList = catList + tag.slug + `/`
        }
    })
    tagMap.set(`cat`, catList)
    tagMap.has(`ren`) && tagMap.get(`ren`) === `index` ? cleanUrl = `` : cleanUrl
    //console.log(tagMap)

    let mkt = tagMap.has(`mkt`) && tagMap.get(`mkt`) !== tagDefaults[def].mkt ? `${tagMap.get(`mkt`)}/` : ``
    let lng = tagMap.has(`lng`) && tagMap.get(`lng`) !== tagDefaults[def].lng ? `${tagMap.get(`lng`)}/` : ``
    let sct = tagMap.has(`sct`) && tagMap.get(`sct`) !== tagDefaults[def].sct ? `${tagMap.get(`sct`)}/` : ``
    let cat = catList
    //iterate for each value of set and output an array >for after

    // URL shortening for defaults

    //sample url structure including all tag categories
    let finurl = `/${mkt}${lng}${sct}${cat}${cleanUrl}`

    // array of different urls that belongs? Not for now
    //Replace old url with new
    // node.url = finurl
    // console.log(finurl)
    return finurl
}

const UrlFilter = (list,url,constraintArray) => {
    let filteredList = list
    constraintArray.forEach((parameter) => {
        const filtParam = new RegExp(parameter[1])
        filteredList = filteredList.filter((item) => {
            if (parameter[0] === `!`) {
                return !filtParam.test(item[url])
            } else {
                return filtParam.test(item[url])
            }
        })
    })

    return filteredList
}

//filters an edge according to a list of constraints

const EdgeTagFilter = (edges,constraintArray) => {
    let nodes = edges
    constraintArray.forEach((parameter) => {
        nodes = nodes.filter(object => object && object.node.tags.filter(tag => tag.name === parameter).length > 0)
    })

    return nodes
}

export { UrlForm, UrlDeform, UrlNav, UrlFilter, EdgeTagFilter }
