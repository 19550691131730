import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { UrlDeform, EdgeTagFilter, UrlNav } from "./UrlFormater";
import expandMore from "../../../static/images/expand_more-blue-28dp.svg";
/**
 * Navigation component
 *
 * The Navigation component takes an array of your Ghost
 * navigation property that is fetched from the settings.
 * It differentiates between absolute (external) and relative link (internal).
 * You can pass it a custom class for your own styles, but it will always fallback
 * to a `site-nav-item` class.
 *
 */
//UrlDeform(navItem.url).get(`furl`)
//
const Navigation = ({ data, navClass, posts, state }) => {
    // const [navimage, setNavimage] = useState(``)
    // const active = () => {
    //     setNavimage(`active`)
    // }
    // const noactive = () => {
    //     setNavimage(``)
    // }
    // onMouseEnter={active} onMouseLeave={noactive}

    const [subMenuState, setSubMenuState] = useState(`subnav-content`);

    const menufilt = (posts, state, navItem, edge) => {
        const tags = edge.node.tags
            .filter((tag) => tag && tag.visibility === `public`)
            .map((node) => node.name);

        return EdgeTagFilter(
            posts,
            [
                `${state.mkt}`,
                `${state.lng}`,
                `${state.sct}`,
                `${navItem.label}`,
            ].concat(tags)
        )
            .filter((node) => node.node.primary_tag === null)
            .map((edge) => (
                <div key={edge.node.id} className="subCatNav">
                    <Link to={UrlNav(edge.node)}>
                        {`${edge.node.title}`}
                        {/*if no child show link else show childs */}
                        <div className="nav-image">
                            <GatsbyImage
                                alt=""
                                className="nav-image-in"
                                image={
                                    edge.node.feature_image3.childImageSharp
                                        .gatsbyImageData
                                }
                            />
                        </div>
                    </Link>
                </div>
            ));
    };

    const submenu = (e) => {
        if (
            e.target.parentElement.parentElement.getAttribute(`class`) ===
            `subnav`
        ) {
            e.target.parentElement.parentElement.setAttribute(
                `class`,
                `subnav open`
            );
        } else {
            e.target.parentElement.parentElement.setAttribute(
                `class`,
                `subnav`
            );
        }
    };

    return (
        <>
            {data.map((navItem, i) => {
                if (navItem.url !== null) {
                    if (navItem.url.match(/^\s?http(s?)/gi)) {
                        return (
                            <a
                                className={navClass}
                                href={navItem.url}
                                key={i}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {navItem.label}
                            </a>
                        );
                    } else if (state !== undefined) {
                        return (
                            <div className="subnav" key={i}>
                                <div className="subnavbtn">
                                    <Link
                                        key={i}
                                        to={UrlDeform(navItem.url).get(`furl`)}
                                    >
                                        {navItem.label}
                                    </Link>
                                    <img
                                        src={expandMore}
                                        className="subnav-open"
                                        onClick={submenu}
                                    />
                                </div>
                                <div className="subnav-content">
                                    {EdgeTagFilter(posts, [
                                        `${state.mkt}`,
                                        `${state.lng}`,
                                        `${state.sct}`,
                                        `${navItem.label}`,
                                        `#ren@topCat`,
                                    ]).map((edge) => (
                                        <div key={edge.node.id}>
                                            <Link to={UrlNav(edge.node)}>
                                                {`${edge.node.title}`}
                                                <div className="nav-image">
                                                    <GatsbyImage
                                                        alt=""
                                                        className="nav-image-in"
                                                        image={
                                                            edge.node
                                                                .feature_image3
                                                                .childImageSharp
                                                                .gatsbyImageData
                                                        }
                                                    />
                                                </div>
                                            </Link>
                                            {menufilt(
                                                posts,
                                                state,
                                                navItem,
                                                edge
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    } else {
                        return (
                            <Link
                                className="subnavbtn"
                                key={i}
                                to={UrlDeform(navItem.url).get(`furl`)}
                            >
                                {navItem.label}
                            </Link>
                        );
                    }
                }
            })}
        </>
    );
};

Navigation.defaultProps = {
    navClass: `site-nav-item`,
};

Navigation.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
    navClass: PropTypes.string,
    posts: PropTypes.array,
    state: PropTypes.object,
    subnav: PropTypes.array,
};

export default Navigation;
