import React, { useEffect, useState } from "react";
import "../../styles/cookie-popup.css";
import cookie from "../../../static/images/cookie-icon-40x40.png";

const CookiePopup = () => {
    const [show, setShow] = useState(false);
    useEffect(async () => {
        const consentCookie = await JSON.parse(localStorage.getItem(`consent`));
        if (consentCookie === null) {
            setShow(true);
        }
    }, []);

    function hideConsent() {
        setShow(false);
    }

    function consent() {
        localStorage.setItem(`consent`, `true`);
        hideConsent();
        document.cookie = "gdpr-analytics-enabled=true";
        document.cookie = "gdpr-marketing-enabled=true";
        document.cookie = "gatsby-gdpr-google-analytics=true";
        document.cookie = "gatsby-gdpr-google-tagmanager=true";
        document.cookie = "gatsby-gdpr-facebook-pixel=true";
    }
    function noConsent() {
        localStorage.setItem(`consent`, `false`);
        hideConsent();
        document.cookie = "gdpr-analytics-enabled=false";
        document.cookie = "gdpr-marketing-enabled=false";
        document.cookie = "gatsby-gdpr-google-analytics=false";
        document.cookie = "gatsby-gdpr-google-tagmanager=false";
        document.cookie = "gatsby-gdpr-facebook-pixel=false";
    }

    return (
        <>
            {show && (
                <div className="cookie-popup">
                    <div className="cookie-popup_text-area">
                        <h1 className="cookie-popup_heading">
                            Σεβόμαστε την ιδιωτικότητά σας
                        </h1>
                        <div className="cookie-popup_text">
                            <p>
                                Θέλουμε να χρησιμοποιήσουμε cookies για να
                                βελτιώσουμε την εμπειρία σας στον ιστότοπό μας.
                                Μπορείτε να διαβάσετε περισσότερα γι΄ αυτό, όπως
                                και να αλλάξετε τις επιλογές σας oποιαδήποτε
                                στιγμή στην
                                <a
                                    href="/aporito-cookie-policy/"
                                    onClick={noConsent}
                                    alt="Πολιτική και ρυθμίσεις cookies"
                                >
                                    Πολιτική και ρυθμίσεις cookies
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="cookie-popup_button-area">
                        <button
                            type="button"
                            id="allow-cookie"
                            onClick={consent}
                            data-dismiss="modal"
                        >
                            Επιτρέψτε τα cookies
                            <img
                                src={cookie}
                                alt="cookie icon"
                                width="30"
                                height="30"
                            />
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default CookiePopup;
