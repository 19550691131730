import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
//import ContentContext from './ContentContext'
//import { readingTime as readingTimeHelper } from '@tryghost/helpers'

const IconCard = ({ post }) => (
    <div className="post-card t-w-350">
        <header className="post-card-header">
            {post.feature_image && (
                <GatsbyImage
                    className="icon-card-image"
                    image={post.feature_image4.childImageSharp.gatsbyImageData}
                    alt=""
                />
            )}
            <h2 className="post-card-title">{post.title}</h2>
        </header>
    </div>
);

IconCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image1: PropTypes.object,
    }).isRequired,
};

export default IconCard;
