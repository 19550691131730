import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { Tags } from "@tryghost/helpers-gatsby";
//import ContentContext from './ContentContext'
//import { readingTime as readingTimeHelper } from '@tryghost/helpers'
import { UrlForm } from "./UrlFormater";

const PostCard = ({ post }) => (
    <Link to={UrlForm(post)} className="post-card">
        <header className="post-card-header">
            {post.feature_image1 && (
                <GatsbyImage
                    alt=""
                    className="post-card-image"
                    image={post.feature_image2.childImageSharp.gatsbyImageData}
                />
            )}

            <h2 className="post-card-title">{post.title}</h2>
        </header>
        <section className="post-card-excerpt">{post.excerpt}</section>
        <footer className="post-card-footer">
            <div className="post-card-footer-left">
                {post.tags && (
                    <div className="post-card-tags">
                        {" "}
                        <Tags
                            post={post}
                            visibility="public"
                            autolink={false}
                        />
                    </div>
                )}
                {post.featured && <span>Featured</span>}
            </div>
        </footer>
    </Link>
);

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image1: PropTypes.object,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
};

export default PostCard;
