import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

const SideCard = ({ post }) => (
    <div className="side-card t-w-350">
        <header className="post-card-header">
            {post.feature_image1 && (
                <div className="side-card-image">
                    <GatsbyImage
                        alt=""
                        image={
                            post.feature_image4.childImageSharp.gatsbyImageData
                        }
                    />
                </div>
            )}
            <h2 className="side-card-title">{post.title}</h2>
        </header>
    </div>
);

SideCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image1: PropTypes.object,
    }).isRequired,
};

export default SideCard;
