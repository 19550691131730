import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { Link as Div, StaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { UrlFilter } from "./UrlFormater";
//import HideSecurity from './HideSecurity'
import { Navigation } from ".";
import CookiePopup from "./CookiePopup";
//import {FirebaseContext, useAuth} from '../Firebase'
//import config from '../../utils/siteConfig'

// Styles
import "../../styles/app-blue.css";
//import TagManagement from './TagManagement'
import ContentContext from "./ContentContext";
import BodyTags from "./bodyTags";
//import ContentContext from '../../../gatsby-browser'
//import '../../styles/global.css'
//import '../../styles/screen.css'

/**
 * Main layout component
 *
 * The Layout component wraps around each page and template.
 * It also provides the header, footer as well as the main
 * styles, and meta data for each page.
 *
 */

//Here there will be taken the parameters for content rendering -> lang, bus/home content, menu, a/b testing. Parameters then will be communicated to other elements in order to filter content

const DefaultLayout = ({ data, children, bodyClass, isHome, pageData }) => {
    const site = data.allGhostSettings.edges[0].node;
    const posts = data.allGhostPost.edges;
    // replaced twitter manually with instagram
    // const twitterUrl = site.twitter
    //     ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}`
    //     : null;
    const facebookUrl = site.facebook
        ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}`
        : null;

    const [menuState, setMenuState] = useState(`site-mast`);
    const [conState, setConState] = useState(true);
    const menu = () => {
        if (menuState === `site-mast`) {
            setMenuState(`site-mast responsive`);
        } else {
            setMenuState(`site-mast`);
        }
    };

    useEffect(() => {
        let element = document.getElementById(`cookie-selection`);
        if (element !== null) {
            let consent = JSON.parse(localStorage.getItem(`consent`));
            setConState(consent);
            if (consent === null) {
                document.getElementById(
                    `consent_performanceTargeting`
                ).checked = false;
            } else {
                document.getElementById(
                    `consent_performanceTargeting`
                ).checked = consent;
            }

            document.getElementById(`consent_save`).onclick = () => {
                localStorage.setItem(
                    `consent`,
                    document.getElementById(`consent_performanceTargeting`)
                        .checked
                );
                if (
                    document.getElementById(`consent_performanceTargeting`)
                        .checked === false
                ) {
                    document.cookie = "gdpr-analytics-enabled=false";
                    document.cookie = "gdpr-marketing-enabled=false";
                    document.cookie = "gatsby-gdpr-google-analytics=false";
                    document.cookie = "gatsby-gdpr-google-tagmanager=false";
                    document.cookie = "gatsby-gdpr-facebook-pixel=false";
                } else {
                    document.cookie = "gdpr-analytics-enabled=true";
                    document.cookie = "gdpr-marketing-enabled=true";
                    document.cookie = "gatsby-gdpr-google-analytics=true";
                    document.cookie = "gatsby-gdpr-google-tagmanager=true";
                    document.cookie = "gatsby-gdpr-facebook-pixel=true";
                }
            };
        }
    });
    const [state, setState] = useContext(ContentContext);
    const changeLang = () => {
        const lang = state.lng === `#lng@el` ? `#lng@en` : `#lng@el`;
        setState({ ...state, lng: lang });
    };

    // const gtmscript =  `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    // new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    // j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    // 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    // })(window,document,'script','dataLayer','GTM-5BH8ZL2');`

    // const hotjarscript = `(function(h,o,t,j,a,r){
    //         h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    //         h._hjSettings={hjid:1859250,hjsv:6};
    //         a=o.getElementsByTagName('head')[0];
    //         r=o.createElement('script');r.async=1;
    //         r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    //         a.appendChild(r);
    //     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`

    //Τα tags έρχονται με σειρά και αυτό που είναι visibility public και δεν έχουν type θα τα λαμβάνουμε ως cat και το πρώτο που θα λαμβάνουμε θα είναι και το κύριως και τα υπόλυπα υποκατηγορίες με την σειρά που θα τα λαμβάνουμε
    // το slug θα το πέρνουμε από το slug και την τελική διεύθυνση μπορούμε να την φορτώσουμε στην url
    //Μενού να αφαιρεί τα tags και να τα χρησιμοποιεί σαν φίλτρα για το αν θα εμφανιστεί το μενού ανάλογα το context που βρίσκεται η σελίδα αυτή την στιγμή και να τα αντικαθιστά με τα κατάλληλα που πρέπει να φαίνονται στην διεύθυνση
    // Αν γίνει αλλαγή κάποιου παράγοντα πχ γλώσσα ή αγορά τότε να αλλάζει ανάλογα τα tags του φίλτρου για να εμφανιστούν τα νέα κατάληλα μενού
    // θα έχω λίστα με προτιμώμενα tags για κάθε μενού και ρύθμιση ώστε να επιλέγονται για την προβολή της κάθε σελίδας.
    //`url(${site.cover_image1.childImageSharp.fluid.src})`
    //console.log(pageData.ghostPage)
    //<HideSecurity />
    //<script>{conState === true && gtmscript}</script>
    //<script>{conState === true && hotjarscript}</script>
    return (
        <>
            <Helmet>
                <html lang={site.lang} />
                <style type="text/css">{`${site.codeinjection_styles}`}</style>
                <body className={bodyClass} />
            </Helmet>
            <BodyTags />
            <div className="viewport">
                <div className="viewport-top">
                    {/* The main header section on top of the screen */}
                    <header
                        className={`${isHome ? `site-head_home` : `site-head`}`}
                    >
                        <div className="opacity">
                            <nav className="top-menu">
                                <Navigation
                                    data={UrlFilter(
                                        site.secondary_navigation,
                                        `url`,
                                        [
                                            [`+`, `${state.lng}`],
                                            [`+`, `#sct@`],
                                        ]
                                    )}
                                    navClass="top-menu-item"
                                />
                            </nav>
                            <div className="container">
                                <button id="burger" onClick={menu}>
                                    &#9776;
                                </button>
                                <div className={menuState} id="Topnav">
                                    <Div
                                        to={
                                            state.lng === `#lng@el`
                                                ? `/`
                                                : `/en/`
                                        }
                                        className="site-mast-left"
                                    >
                                        {site.logo ? (
                                            <img
                                                className="site-logo"
                                                src={site.logo}
                                                alt={site.title}
                                            />
                                        ) : (
                                            <GatsbyImage
                                                image={
                                                    data.file.childImageSharp
                                                        .gatsbyImageData
                                                }
                                                alt={site.title}
                                            />
                                        )}
                                    </Div>

                                    <nav className="site-nav">
                                        {/* The navigation items as setup in Ghost */}

                                        <Navigation
                                            data={UrlFilter(
                                                site.navigation,
                                                `url`,
                                                [
                                                    [`+`, `${state.mkt}`],
                                                    [`+`, `${state.lng}`],
                                                    [`+`, `${state.sct}`],
                                                ]
                                            )}
                                            navClass="site-nav-item"
                                            posts={posts}
                                            state={state}
                                        />
                                    </nav>

                                    <div className="site-mast-right">
                                        <Div
                                            className="call"
                                            to={
                                                state.lng === `#lng@el`
                                                    ? `/epikoinonia/`
                                                    : `/contact/`
                                            }
                                        >
                                            {state.lng === `#lng@el`
                                                ? `Για ότι χρειαστείτε`
                                                : `Contact us`}
                                            <br />
                                            2150000279
                                        </Div>
                                        {/*site.twitter &&*/
                                        /*<a
                                                href={`https://www.instagram.com/xorosmou/`}
                                                className="site-nav-item"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img
                                                    className="site-nav-icon"
                                                    src="/images/icons/instagram.svg"
                                                    alt="Instagram"
                                                />
                                            </a>*/}
                                        {/*site.facebook && (
                                            <a
                                                href={facebookUrl}
                                                className="site-nav-item"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img
                                                    className="site-nav-icon"
                                                    src="/images/icons/facebook.svg"
                                                    alt="Facebook"
                                                />
                                            </a>
                                        )*/}
                                    </div>
                                </div>

                                <hr className="nav-hr" />
                            </div>
                        </div>
                    </header>

                    <main
                        className={`${isHome ? `site-main_home` : `site-main`}`}
                    >
                        {isHome ? (
                            <div>
                                <GatsbyImage
                                    alt=""
                                    image={
                                        pageData.ghostPage.feature_image1
                                            .childImageSharp.gatsbyImageData
                                    }
                                    className="site-banner"
                                />
                                <div className="site-banner__text">
                                    <h1 className="site-banner-title">
                                        {site.title}
                                    </h1>
                                    <p className="site-banner-desc">
                                        {pageData.ghostPage.title}
                                    </p>
                                </div>
                            </div>
                        ) : null}
                        {/* All the main content gets inserted here, index.js, post.js */}
                        {children}
                    </main>
                </div>

                <div className="viewport-bottom">
                    {/* The footer at the very bottom of the screen */}
                    <footer className="site-foot">
                        <div className="site-foot-nav container">
                            <div className="site-foot-nav-left">
                                <Div
                                    to={state.lng === `#lng@el` ? `/` : `/en/`}
                                >
                                    {site.title}&#8239;©{" "}
                                    {new Date().getFullYear()}
                                </Div>
                            </div>
                            <div className="site-foot-nav-right">
                                <Navigation
                                    data={UrlFilter(
                                        site.secondary_navigation,
                                        `url`,
                                        [
                                            [`+`, `${state.lng}`],
                                            [`!`, `#sct@`],
                                        ]
                                    )}
                                    navClass="site-foot-nav-item"
                                />
                                <b>2150000279</b>
                            </div>
                        </div>
                    </footer>
                    <CookiePopup />
                </div>
            </div>
        </>
    );
};

//<Button className="site-nav-button" onClick={changeLang} to={state.lng === `#lng@el` ? `/en/` : `/`} >{state.lng === `#lng@el` ? `En` : `Gr`}</Button>

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    pageData: PropTypes.object,
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
        allGhostPost: PropTypes.object,
    }).isRequired,
};

const DefaultLayoutSettingsQuery = (props) => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: { eq: "ghost-icon.png" }) {
                    childImageSharp {
                        gatsbyImageData(layout: FIXED, width: 30, height: 30)
                    }
                }
                allGhostPost(sort: { fields: updated_at, order: ASC }) {
                    edges {
                        node {
                            ...GhostPostFields
                        }
                    }
                }
            }
        `}
        render={(data) => <DefaultLayout data={data} {...props} />}
    />
);

export default DefaultLayoutSettingsQuery;
